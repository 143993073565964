/**
 * @fileoverview Tooltipを表示するComponentの定義
 * @author Taketoshi Aono
 */

import React, { useRef, useEffect } from 'react';
// import assert from 'assert';
import { css, Global } from '@emotion/react';
import { createPortal } from 'react-dom';
import { v4 } from 'uuid';
import { smallTextStyle } from '@s/components/atom/Text';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { forceLayout } from '@aim/shared/src/components/atom/domUtils';
import { Root, createRoot } from 'react-dom/client';

/**
 * tooltipを隠す
 */
const hide = () => {
  const tooltip = document.getElementById(ID);
  if (tooltip && (tooltip as any)['__visible']) {
    tooltip.style.transform = '';
  }
};

const HIDE_TIME_MS = 100;

export type Direction = 'left' | 'right' | 'center' | 'side-left';
type ActivationTrigger = 'hover' | 'click';

const timeoutIds: any[] = [];
const hideTimeoutIds: any[] = [];

const ID = `aim-tooltip-${v4()}`;
let root: Root;
const WHITE_CLASS = `white-${ID}`;
const RIGHT_CLASS = `right-${ID}`;
const LEFT_CLASS = `left-${ID}`;
const TOP_CLASS = `top-${ID}`;
const BOTTOM_CLASS = `bottom-${ID}`;
const LEFT_SIDE_CLASS = `left-side-${ID}`;
export const TooltipGlobalStyles = css`
  #${ID} {
    ${smallTextStyle};
    position: absolute;
    display: none;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999999;
    padding: 10px 15px;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 0.2px 0.2px rgba(0, 0, 0, 0.013), 0 0.3px 0.3px rgba(0, 0, 0, 0.019),
      0 0.6px 0.6px rgba(0, 0, 0, 0.023), 0 0.9px 0.9px rgba(0, 0, 0, 0.027),
      0 1.3px 1.3px rgba(0, 0, 0, 0.03), 0 1.8px 1.8px rgba(0, 0, 0, 0.033),
      0 2.5px 2.5px rgba(0, 0, 0, 0.037), 0 3.7px 3.7px rgba(0, 0, 0, 0.041),
      0 5.6px 5.6px rgba(0, 0, 0, 0.047), 0 10px 10px rgba(0, 0, 0, 0.06);
    margin: 0px 0px 0px 0px;
    transition: transform 0.1s;
    transform: scale(0);
    transform-origin: 50% 0;
    opacity: 0.8;
    max-width: 50vw;
    backdrop-filter: blur(20px);
    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 8.7px 5px;
      border-color: transparent transparent #000000 transparent;
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -5px;
      opacity: 0.6;
    }
  }
  .${TOP_CLASS} {
    &:before {
      top: -8px;
    }
  }
  .${BOTTOM_CLASS} {
    &:before {
      transform: rotate(180deg);
      bottom: -8px;
    }
  }
  .${WHITE_CLASS} {
    opacity: 1;
    background: #fff !important;
    color: #333 !important;
    &:before {
      background: #fff !important;
    }
  }
  .${RIGHT_CLASS} {
    &:before {
      left: 100% !important;
      margin-left: -16px !important;
    }
  }
  .${LEFT_CLASS} {
    &:before {
      left: 0% !important;
      margin-left: 16px !important;
    }
  }
  .${LEFT_SIDE_CLASS} {
    &:before {
      top: calc(50% - 5px) !important;
      left: 0% !important;
      margin-left: -10px !important;
      transform: rotate(270deg);
    }
  }
`;

export const FlyweightGlobalTooltip = compareOnlyProperties(() => {
  useEffect(() => {
    const handler = () => {
      hideTimeoutIds.push(setTimeout(hide, 300));
    };
    document.documentElement.addEventListener('mouseover', handler);
    return () => {
      document.documentElement.removeEventListener('mouseover', handler);
    };
  }, []);
  return (
    <>
      <Global styles={TooltipGlobalStyles} />
      {createPortal(
        <div
          id={ID}
          onMouseOver={e => {
            e.stopPropagation();
            hideTimeoutIds.forEach(v => clearTimeout(v));
            hideTimeoutIds.length = 0;
          }}
          onMouseLeave={e => {
            e.stopPropagation();
            timeoutIds.forEach(v => clearTimeout(v));
            timeoutIds.length = 0;
          }}
        ></div>,
        document.body
      )}
    </>
  );
});

/**
 * tooltipを表示する
 */
const displayTooltip = ({
  useBrightTheme,
  target,
  useHtml,
  label,
  delay,
  margin,
  forceDirection,
  timeoutIds,
  onGetWidth,
}: {
  useBrightTheme?: boolean;
  target: Element | null | undefined;
  useHtml?: boolean;
  label: string | React.ReactElement;
  timeoutIds: any[];
  forceDirection?: Direction;
  delay?: number;
  margin?: string;
  onGetWidth?(el: Element): number;
}) => {
  const DEFAULT_TIMEOUT_MS = 800;
  timeoutIds.push(
    window.setTimeout(
      () => {
        const tooltip = document.getElementById(ID);
        // すでにrootが作成されていれば既存のものを使用
        // 新規作成すると warning が出る
        if (!root) root = createRoot(tooltip!);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (tooltip && document.documentElement) {
          tooltip.classList.remove(WHITE_CLASS);
          tooltip.classList.remove(RIGHT_CLASS);
          tooltip.classList.remove(LEFT_CLASS);
          tooltip.classList.remove(LEFT_SIDE_CLASS);
          tooltip.classList.remove(TOP_CLASS);
          tooltip.classList.remove(BOTTOM_CLASS);

          if (useBrightTheme) {
            tooltip.classList.add(WHITE_CLASS);
          }
          const el = target;
          if (el && document.body.contains(el)) {
            const rect = el.getBoundingClientRect();
            root.render(useHtml ? (label as React.ReactElement) : <>{label}</>);
            tooltip.style.display = 'block';

            let top = rect.top + rect.height - 10;
            let isVerticallyReversed = false;
            if (forceDirection !== 'side-left' && top + rect.height > window.innerHeight - 50) {
              top = rect.top - rect.height + 10;
              tooltip.classList.add(BOTTOM_CLASS);
              isVerticallyReversed = true;
            } else if (forceDirection !== 'side-left') {
              tooltip.classList.add(TOP_CLASS);
            }
            const baseElementWidth = onGetWidth ? onGetWidth(el) : el.clientWidth;

            let left =
              rect.left +
              (baseElementWidth >> 1) +
              (document.documentElement.scrollLeft || document.body.scrollLeft) -
              (tooltip.clientWidth >> 1);

            let direction: Direction = 'center';
            if (forceDirection === 'side-left') {
              tooltip.classList.add(LEFT_SIDE_CLASS);
              left = rect.left + rect.width + 10;
              direction = 'side-left';
              top = rect.top - (rect.height >> 1);
            } else if (forceDirection !== 'center') {
              if (
                forceDirection === 'right' ||
                left + tooltip.clientWidth > document.documentElement.clientWidth
              ) {
                tooltip.classList.add(RIGHT_CLASS);
                left -= tooltip.clientWidth >> 1;
                direction = 'right';
              } else if (forceDirection === 'left' || left < 0) {
                tooltip.classList.add(LEFT_CLASS);
                left = rect.left;
                direction = 'left';
              }
            }
            let cssText = `top: ${top}px;left: ${left}px;display: block; transform: scale(1); transform-origin: ${
              direction === 'left' ? '0px 0px' : direction === 'right' ? '100% 0' : '50% 0'
            };`;
            tooltip.style.display = 'none';
            // Marginが入ってる場合はcssに追加
            if (margin != null) {
              cssText += `margin: ${margin}`;
            }
            tooltip.style.cssText = cssText;
            (tooltip as any)['__visible'] = true;
            if (isVerticallyReversed) {
              forceLayout(tooltip);
              const marginTop = parseInt(
                document.defaultView!.getComputedStyle(tooltip).marginTop,
                10
              );
              tooltip.style.cssText = `${cssText};margin-top: ${-marginTop}px !important`;
            }
          }
        }
      },
      delay != null ? delay : DEFAULT_TIMEOUT_MS
    )
  );
};

export interface Props {
  margin?: string;
  useHtml?: boolean;
  delay?: number;
  width?: number | string;
  useBrightTheme?: boolean;
  forceDirection?: Direction;
  label: string | React.ReactElement;
  onGetWidth?(node: HTMLElement): number;
  activationTrigger?: ActivationTrigger;
}

/**
 * Tooltipを表示するComponent
 */
export const Tooltip = compareOnlyProperties(
  ({
    children,
    margin,
    useBrightTheme,
    delay,
    label,
    useHtml = false,
    width = 'auto',
    forceDirection,
    onGetWidth,
    activationTrigger = 'hover',
  }: Props & { children: React.ReactChild }) => {
    const target = useRef<HTMLElement | null>(null);
    // assert.strictEqual(React.Children.count(children), 1, 'Tooltip only accept only one child');
    const child: React.ReactElement<any, any> = React.Children.toArray(children)[0] as any;
    const isTextNode = typeof child === 'string';
    const isReactComponent = !isTextNode && typeof (child as any).type === 'function';
    // assert.strictEqual(
    //   React.isValidElement(child) || isTextNode,
    //   true,
    //   // eslint-disable-next-line  @typescript-eslint/restrict-template-expressions
    //   `Child of Tooltip must be a valid ReactElement.\nBut got ${child}`
    // );
    const ref = (e: HTMLElement) => {
      if (!isTextNode && typeof child.props.ref === 'function') {
        child.props.ref(e);
      }
      target.current = e;
    };

    useEffect(() => {
      return () => hide();
    }, []);

    const clearHide = () => {
      hideTimeoutIds.forEach(v => clearTimeout(v));
      hideTimeoutIds.length = 0;
    };

    const clearDisplay = () => {
      timeoutIds.forEach(v => clearTimeout(v));
      timeoutIds.length = 0;
    };

    const tooltipActivate = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      clearHide();
      clearDisplay();
      displayTooltip({
        margin,
        useHtml,
        delay,
        useBrightTheme,
        label,
        target: target.current,
        forceDirection,
        timeoutIds,
        onGetWidth,
      });
    };

    const handleMouseOut = (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      clearHide();
      clearDisplay();
      if (!document.body.contains(target.current)) {
        return hide();
      }
      hideTimeoutIds.push(
        window.setTimeout(() => {
          clearHide();
          hide();
        }, HIDE_TIME_MS)
      );
    };

    const onMouseLeave = (e: React.MouseEvent<any>) => handleMouseOut(e);
    const onMouseEnter = (e: React.MouseEvent<any>) => {
      if (activationTrigger === 'hover') tooltipActivate(e);
    };
    const onMouseClick = (e: React.MouseEvent<any>) => {
      if (activationTrigger === 'click') tooltipActivate(e);
    };

    return (
      <>
        {isReactComponent || isTextNode ? (
          <span
            className="tooltip-hover"
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseEnter}
            onClick={onMouseClick}
            ref={ref}
            css={{ display: 'inline-block', width }}
          >
            {child}
          </span>
        ) : (
          React.cloneElement(child, {
            className: `${(child.props.className as string) || ''} tooltip-hover`,
            onMouseLeave: child.props.onMouseLeave
              ? (e: React.MouseEvent<any>) => {
                  child.props.onMouseLeave(e);
                  onMouseLeave(e);
                }
              : onMouseLeave,
            onMouseOver: child.props.onMouseOver
              ? (e: React.MouseEvent<any>) => {
                  child.props.onMouseOver(e);
                  onMouseEnter(e);
                }
              : onMouseEnter,
            onClick: child.props.onClick
              ? (e: React.MouseEvent<any>) => {
                  child.props.onClick(e);
                  onMouseClick(e);
                }
              : onMouseClick,
            ref,
          } as any)
        )}
      </>
    );
  },
  'Tooltip'
);
