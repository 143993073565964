/**
 * @fileOverview
 * @author Mana Horie
 */

import { GettableQuery } from '@s/Query';
import { fetchService } from '../../src/io/fetchService';

export type CustomerMediaGettableQuery = GettableQuery<{ url: string; token?: string }, string>;

export class CustomerMediaQuery implements CustomerMediaGettableQuery {
  public async get({
    url,
    token,
  }: Parameters<CustomerMediaGettableQuery['get']>[0]): ReturnType<
    CustomerMediaGettableQuery['get']
  > {
    return window.URL.createObjectURL(
      await fetchService(url, {
        method: 'GET',
        responseType: 'blob',
        ...(token ? { headers: { authorization: `Bearer ${token}` } } : {}),
      })
    );
  }
}
